// ** Icons Import
import {
  Mail,
  MessageSquare,
  CheckSquare,
  Calendar,
  FileText,
  Circle,
  ShoppingCart,
  User,
  Shield,
  Home,
  Menu, AlertOctagon, Triangle, AlertTriangle
} from 'react-feather'
import {getMachines} from "@utils"

export default [
  {
    header: 'Zobrazení',
    action: 'read',
    resource: 'overview'
  },
  {
    id: 'overview',
    title: 'Přehled',
    icon: <Circle size={12}/>,
    navLink: '/overview',
    action: 'read',
    resource: 'overview'
  },
  {
    id: 'myDowntimeEvents',
    title: 'Moje prostoje',
    icon: <AlertTriangle size={20}/>,
    navLink: '/my-downtime-events',
    action: 'read',
    resource: 'machines',
    badge: 'light-danger',
    badgeText: '3'
  },
  // {
  //   id: 'shift',
  //   title: 'Směny',
  //   icon: <Calendar size={12}/>,
  //   navLink: '/shift'
  // },
  {
    header: 'Stroje',
    action: 'read',
    resource: 'machines'
  },
  ...getMachines().map(m => ({
    id: m.name,
    title: m.label,
    icon: <Circle size={8}/>,
    navLink: `/machines/${m.name}`,
    action: 'read',
    resource: 'machines'
  })),
  {
    header: 'Ostatní',
    action: 'read',
    resource: 'machines'
  },
  {
    id: 'list',
    title: 'Uživatelé',
    icon: <User size={12}/>,
    navLink: '/user/list',
    action: 'read',
    resource: 'users'
  },
  {
    id: 'downtime',
    title: 'Typy prostojů',
    icon: <AlertOctagon size={12}/>,
    navLink: '/downtime',
    action: 'read',
    resource: 'machines'
  }
]
