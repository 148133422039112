// ** Icons Import
import {Heart} from 'react-feather'

const Footer = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-start d-block d-md-inline-block mt-25'>
        {/*COPYRIGHT © {new Date().getFullYear()}{' '}*/}
        <span className='d-none d-sm-inline-block'>Pro &nbsp;</span>
        <a href='https://axis-hk.cz' target='_blank' rel='noopener noreferrer'>
          Axis a.s.
        </a>
        <span className='d-none d-sm-inline-block'>&nbsp;s péčí vytvořili &nbsp;</span>
        <a href='https://epr-liberec.cz' target='_blank' rel='noopener noreferrer'>
          EPR s.r.o.
        </a>
      </span>
      {/*<span className='float-md-end d-none d-md-block'>*/}
      {/*  Hand-crafted & Made with*/}
      {/*  <Heart size={14} />*/}
      {/*</span>*/}
    </p>
  )
}

export default Footer
