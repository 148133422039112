// ** React Imports
import {Fragment} from 'react'
import {NavItem, NavLink} from "reactstrap"
import {ArrowRightCircle} from "react-feather"

// ** Custom Components

// import { useDateRange } from '@hooks/useDateRange'


// eslint-disable-next-line no-unused-vars
const FloatingMenu = props => {
  // ** Props
  // const {skin, setSkin, setMenuVisibility} = props
  const {setMenuVisibility} = props
  
  return (
    <Fragment>
      <div className='d-xl-none floating-menu'>
        <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
          <ArrowRightCircle/>
        </NavLink>
      </div>
    </Fragment>
  )
}

export default FloatingMenu
