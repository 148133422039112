//Address of the AiD REST API
import axios from "axios"

import {ToastError} from "@utils"
import toast from "react-hot-toast"

const apiAiD = {
  address: 'https://api-axis.epr-liberec.cz',
  crud: '/crud'
  // address:"http://10.10.1.59:3000"
}

axios.defaults.timeout = 10000

// ** Add request/response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    // ** const { config, response: { status } } = error
    
    const {config, response} = error
    // const originalRequest = config
    
    if (config.url.startsWith(apiAiD.address)) {
      toast(t => <ToastError t={t}
                             message={`Nepodařilo se spojit se API serverem.\n Kód: ${response && response.status}`}/>)
    }
    
    return Promise.reject(error)
  }
)


export default apiAiD
