// ** React Imports
import {lazy} from 'react'
import {Navigate} from 'react-router-dom'
import BlankLayout from "@layouts/BlankLayout"


//Machines
const MachineView = lazy(() => import('../../views/axis/machines/view/'))
const MyDowntimeEvents = lazy(() => import('../../views/axis/downtime/MyDowntimes'))

//Overview
const Overview = lazy(() => import('../../views/axis/overview'))
const Downtime = lazy(() => import('../../views/axis/downtime'))
const Shift = lazy(() => import('../../views/axis/shift'))


//Users
const User = lazy(() => import('../../views/axis/user/list'))
const UserView = lazy(() => import('../../views/axis/user/view'))

const AppRoutes = [
  {
    element: <Overview/>,
    path: '/overview',
    meta: {
      action: 'read',
      resource: 'overview'
    }
  },
  {
    element: <MyDowntimeEvents/>,
    path: '/my-downtime-events',
    meta: {
      action: 'read',
      resource: 'machines'
    }
  },
  {
    element: <Downtime/>,
    path: '/downtime',
    meta: {
      action: 'read',
      resource: 'machines'
    }
  },
  {
    element: <User/>,
    path: '/user/list',
    meta: {
      action: 'read',
      resource: 'users'
    }
  },
  {
    path: '/user/view',
    element: <Navigate to="/"/>,
    meta: {
      action: 'read',
      resource: 'users'
    }
  },
  {
    element: <UserView/>,
    path: '/user/view/:id'
  },
  //Machines
  {
    element: <MachineView/>,
    path: '/machines/:name',
    meta: {
      action: 'read',
      resource: 'machines'
    }
  }
]

export default AppRoutes
