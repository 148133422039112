/* eslint-disable */
import {DefaultRoute} from '../router/routes'
import moment from 'moment'
import * as d3 from 'd3'
import axios from 'axios'
import toast from 'react-hot-toast'
import Avatar from "@components/avatar";
import {AlertOctagon, Coffee, X} from "react-feather";
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {month: 'short', day: 'numeric', year: 'numeric'}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = {month: 'short', day: 'numeric'}
  
  if (toTimeForCurrentDay && isToday(date)) {
    formatting = {hour: 'numeric', minute: 'numeric'}
  }
  
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  return DefaultRoute
  // console.log(userRole)
  if (userRole) return DefaultRoute
  return '/login'
  
  // if (userRole === 'admin') return DefaultRoute
  // if (userRole === 'client') return '/access-control'
  // return '/login'
}

export const array2tstzRange = arr => {
  // console.log(arr)
  return `["${arr[0] ? arr[0].toISOString() : ``}",${(arr[1] && arr[1] instanceof Date && !isNaN(arr[1])) ? `"${arr[1].toISOString()}"` : ``})`
}

const regexTstz = `([\\+-]?\\d{4}(?!\\d{2}\\b))((-?)((0[1-9]|1[0-2])(\\3([12]\\d|0[1-9]|3[01]))?|W([0-4]\\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\\d|[12]\\d{2}|3([0-5]\\d|6[1-6])))([T\\s]((([01]\\d|2[0-3])((:?)[0-5]\\d)?|24\\:?00)([\\.,]\\d+(?!:))?)?(\\17[0-5]\\d([\\.,]\\d+)?)?([zZ]|([\\+-])([01]\\d|2[0-3]):?([0-5]\\d)?)?)?)?`
const regexTstzStart = new RegExp(`^[\\(\\[]"${regexTstz}",[\\)\\]]$`)
const regexTstzEnd = new RegExp(`^[\\(\\[],"${regexTstz}"[\\)\\]]$`)
const regexTstzComplete = new RegExp(`^[\\(\\[]"${regexTstz}","${regexTstz}"[\\)\\]]$`)

export function tstzrangeValid(str) {
  if (typeof str !== 'string') return null
  return str === '[,)' || regexTstzStart.test(str) || regexTstzEnd.test(str) || regexTstzComplete.test(str) ? str : null
}

export const tstzRange2array = str => {
  let out = []
  if (tstzrangeValid(str) !== null) out = [moment(`Invalid ISO format\n${str}`), moment(`Invalid ISO format\n${str}`)]
  
  if (str === '[,)') {
    out = []
  } else if (regexTstzStart.test(str)) {
    out = [moment(str.split('"')[1]), moment(Infinity)]
  } else if (regexTstzEnd.test(str)) {
    out = [moment(-Infinity), moment(str.split('"')[1])]
  } else if (regexTstzComplete.test(str)) {
    out = [moment(str.split('"')[1]), moment(str.split('"')[3])]
  }
  
  if (!(out[0] !== undefined && (out[0].isValid() || out[0]._i === Infinity || out[0]._i === -Infinity))) out[0] = `Invalid ISO format\n${out[0]}`
  if (!(out[1] !== undefined && (out[1].isValid() || out[1]._i === Infinity || out[1]._i === -Infinity))) out[1] = `Invalid ISO format\n${out[1]}`
  
  return out
  
}

export const ToastSuccess = ({t, message}) => {
  return (
    <div className='d-flex cursor-pointer user-select-none' onClick={() => toast.dismiss(t.id)}>
      <div className='me-1'>
        <Avatar size='sm' color='success' icon={<Coffee size={12}/>}/>
      </div>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h6>{message}</h6>
          <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)}/>
        </div>
      </div>
    </div>
  )
}

export const ToastError = ({t, message}) => {
  return (
    <div className='d-flex cursor-pointer user-select-none' onClick={() => toast.dismiss(t.id)}>
      <div className='me-1'>
        <Avatar size='sm' color='danger' icon={<AlertOctagon size={12}/>}/>
      </div>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h6>{message}</h6>
          <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)}/>
        </div>
      </div>
    </div>
  )
}


// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

const localeDef = {
  dateTime: '%A,%e.%B %Y, %X',
  date: '%-d.%-m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: ['neděle', 'pondělí', 'úterý', 'středa', 'čvrtek', 'pátek', 'sobota'],
  shortDays: ['ne.', 'po.', 'út.', 'st.', 'čt.', 'pá.', 'so.'],
  months: [
    'leden',
    'únor',
    'březen',
    'duben',
    'květen',
    'červen',
    'červenec',
    'srpen',
    'září',
    'říjen',
    'listopad',
    'prosinec'
  ],
  shortMonths: ['led', 'úno', 'břez', 'dub', 'kvě', 'čer', 'červ', 'srp', 'zář', 'říj', 'list', 'pros']
}

const formatMillisecond = d3.timeFormatLocale(localeDef).format('.%L'),
  formatSecond = d3.timeFormatLocale(localeDef).format(':%S'),
  formatMinute = d3.timeFormatLocale(localeDef).format('%H:%M'),
  formatHour = d3.timeFormatLocale(localeDef).format('%Hh'),
  formatDay = d3.timeFormatLocale(localeDef).format('%a %d'),
  formatWeek = d3.timeFormatLocale(localeDef).format('%b %d'),
  formatMonth = d3.timeFormatLocale(localeDef).format('%B'),
  formatYear = d3.timeFormatLocale(localeDef).format('%Y')

export function AiDMultiFormat(date) {
  return (
    d3.timeSecond(date) < date
      ? formatMillisecond
      : d3.timeMinute(date) < date
        ? formatSecond
        : d3.timeHour(date) < date
          ? formatMinute
          : d3.timeDay(date) < date
            ? formatHour
            : d3.timeMonth(date) < date
              ? d3.timeWeek(date) < date
                ? formatDay
                : formatWeek
              : d3.timeYear(date) < date
                ? formatMonth
                : formatYear
  )(date)
}

export function getMachineEventTypes() {
  return [{id: 1, name: "Zapnuto"}, {id: 2, name: "Aktivita"}]
}

export function getMachines() {
  return [
    {
      name: 'fiber-laser',
      label: 'Dělení laserem',
      id: 1
    },
    {
      name: 'metal-bender-3m',
      label: 'Ohraňovací lis 3 m',
      id: 2
    },
    {
      name: 'metal-bender-6m',
      label: 'Ohraňovací lis 6 m',
      id: 3
    },
    {
      name: 'water-cutting',
      label: 'Dělení vodou',
      id: 4
    },
    {
      name: 'welder-stainless',
      label: 'Svařování pod tavidlem',
      id: 5,
      ignoreLastRecord: true
    },
    {
      name: 'welder-plasma',
      label: 'Plazmové svařování',
      id: 6
    }
  ]
}

export const optionsDate = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'}

export const momentToString =
  // eslint-disable-next-line no-confusing-arrow
  (m) => (m && m.isValid() ? m.toDate().toLocaleTimeString('cs', optionsDate) : '')

export const getStartDateString =
  (element) => {
    if (!moment.isMoment(element.start_date)) return "Neplatný formát času"
    return (element.start_date.isValid() ? element.start_date.toDate().toLocaleTimeString('cs', optionsDate) : 'minulost')
  }

export const getEndDateString =
  (element) => {
    if (!moment.isMoment(element.end_date)) return "Neplatný formát času"
    return (element.end_date.isValid() ? element.end_date.toDate().toLocaleTimeString('cs', optionsDate) : 'současnost')
  }

export const formatDuration = ms => {
  if (ms < 0) ms = -ms
  const time = {
    days: Math.floor(ms / 86400000),
    hours: Math.floor(ms / 3600000) % 24,
    minutes: Math.floor(ms / 60000) % 60
    // second: Math.floor(ms / 1000) % 60,
    // millisecond: Math.floor(ms) % 1000
  }
  
  let out = ``
  
  if (time.days !== 0) out = `${time.days} dny`
  
  out = `${out}${out === `` ? `` : ` `}${time.hours >= 10 ? `` : `0`}${time.hours}:${time.minutes >= 10 ? `` : `0`}${time.minutes}`
  
  return out
}


export const getSecondsBetween = (element, limitNow = false) => {
  let end_date = element.end_date
  if ((moment.isMoment(end_date) && (end_date._i === Infinity || (limitNow && end_date.isAfter(moment())))) || !end_date) end_date = moment()
  
  const duration = moment.duration(end_date.diff(element.start_date))
  return duration.asSeconds()
}


export const timeBetween = (element) => {
  return formatDuration(getSecondsBetween(element) * 1000)
}

export const getRangeDateString =
  (element) => `${getStartDateString(element)} - ${getEndDateString(element)} (${timeBetween(element)})`

export const sortEndDate = (rowA, rowB) => {
  
  // console.log(rowA, rowB)
  
  let a_end_date = rowA.end_date
  if (moment.isMoment(a_end_date) && a_end_date._i === Infinity) {
    a_end_date = moment()
  }
  
  let b_end_date = rowB.end_date
  if (moment.isMoment(b_end_date) && b_end_date._i === Infinity) {
    b_end_date = moment()
  }
  
  if (a_end_date > b_end_date) {
    return 1
  }
  
  if (b_end_date > a_end_date) {
    return -1
  }
  
  return 0
}

export const getComparationExpectedReal = (element) => {
  const seconds = getSecondsBetween(element)
  if (!element.erp_operation_time_s) return ``
  return `${(Math.round(seconds))}s/${element.erp_operation_time_s}s - ${Math.round((seconds / element.erp_operation_time_s) * 100)}%`
  
}

export const getUserPersonal = (user) => `${user ? `${user.full_name ? user.full_name : 'Bez jména'} ${user.personal_nr ? `- ${user.personal_nr}` : ``}` : ``}`

// eslint-disable-next-line no-confusing-arrow
export const getDayIndexMondayStart = (i) => (i === 0 ? 6 : (i - 1))


export const paginationComponentOptions = {
  rowsPerPageText: 'Řádků na stánku',
  rangeSeparatorText: 'z',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Všechny'
}