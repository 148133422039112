// ** React Imports
import {Fragment, useEffect, useState} from 'react'

// ** Reactstrap Imports
import {Button, ButtonGroup, Label, NavLink} from 'reactstrap'

// ** Third Party Components
import Flatpickr from 'react-flatpickr'
import {Czech} from 'flatpickr/dist/l10n/cs.js'
import {ChevronLeft, ChevronRight} from 'react-feather'

import {useDateRange} from '@hooks/useDateRange'
import moment from 'moment'
import * as Icon from "react-feather"


// eslint-disable-next-line no-unused-vars
let startDate

const PickerRange = (props) => {
  // ** State
  // const [picker, setPicker] = useState([new Date(), new Date().setDate(new Date().getDate() + 7)])
  const {dateRange, setDateRange} = useDateRange()
  const [datepicker, setDatepicker] = useState(dateRange)
  // eslint-disable-next-line no-unused-vars
  const {setMenuVisibility} = props
  // eslint-disable-next-line no-unused-vars
  const [intervalSelected, setIntervalSelected] = useState('')
  
  function setDateRangePicker(arr) {
    setDatepicker(arr)
    setDateRange(arr)
  }
  
  function setToday() {
    const from = moment()
    
    from.hour(0)
    from.minute(0)
    from.second(0)
    
    const to = from.clone()
    to.add(1, 'day')
    
    setDateRangePicker([from.toDate(), to.toDate()])
  }
  
  function setLastWeek() {
    const from = moment().startOf('isoWeek').hour(0).minute(0)
    const to = moment().endOf('isoWeek').add(1, 'day').hour(0).minute(0)
    
    setDateRangePicker([from.toDate(), to.toDate()])
  }
  
  function setLastMonth() {
    const from = moment().startOf('month').hour(0).minute(0)
    const to = moment().endOf('month').add(1, 'day').hour(0).minute(0)
    
    setDateRangePicker([from.toDate(), to.toDate()])
  }
  
  function moveForward() {
    let from, to
    
    if (intervalSelected !== '') {
      from = moment(dateRange[0]).add(1, intervalSelected)
      to = moment(dateRange[1]).add(1, intervalSelected)
    } else {
      const diff = Math.abs(dateRange[1].valueOf() - dateRange[0].valueOf())
      from = moment(dateRange[1])
      to = moment(dateRange[1]).add(diff)
    }
    
    setDateRangePicker([from.toDate(), to.toDate()])
  }
  
  function moveBackward() {
    let from, to
    
    if (intervalSelected !== '') {
      from = moment(dateRange[0]).add(-1, intervalSelected)
      to = moment(dateRange[1]).add(-1, intervalSelected)
    } else {
      const diff = Math.abs(dateRange[1].valueOf() - dateRange[0].valueOf())
      from = moment(dateRange[0]).subtract(diff)
      to = moment(dateRange[0])
    }
    // console.log([from.toDate(), to.toDate()])
    setDateRangePicker([from.toDate(), to.toDate()])
  }
  
  function checkIntervals() {
    const startDate = moment(dateRange[0])
    const endDate = moment(dateRange[1])
    
    if (
      startDate.clone().hour(0).minute(0).diff(startDate, 'minute') === 0 &&
      startDate.clone().add(1, 'day').hour(0).minute(0).diff(endDate, 'minute') === 0
    ) {
      setIntervalSelected('day')
    } else if (
      startDate.clone().startOf('week').add(1, 'day').hour(0).minute(0).diff(startDate, 'minute') === 0 &&
      startDate.clone().endOf('week').add(2, 'day').hour(0).minute(0).diff(endDate, 'minute') === 0
    ) {
      setIntervalSelected('week')
    } else if (
      startDate.clone().startOf('month').hour(0).minute(0).diff(startDate, 'minute') === 0 &&
      startDate.clone().endOf('month').add(1, 'day').hour(0).minute(0).diff(endDate, 'minute') === 0
    ) {
      setIntervalSelected('month')
    } else {
      setIntervalSelected('')
    }
  }
  
  useEffect(() => {
    checkIntervals()
  }, dateRange)
  
  return (
    <Fragment>
      <div className='emulated-flex-gap'>
        {/*<div className='d-flex align-items-center flex-wrap'>*/}
        {/*<div className='d-xl-none floating-menu'>*/}
        {/*  <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>*/}
        {/*    <Icon.Menu className='ficon'/>*/}
        {/*  </NavLink>*/}
        {/*</div>*/}
        <div>
          <Label className='pt-9px' for='range-picker'>
            Časový rozsah
          </Label>
        </div>
        
        <Flatpickr
          value={datepicker}
          data-enable-time
          id='range-picker'
          className='form-control me-1 pe-0'
          onChange={date => {
            setDatepicker(date)
          }}
          onClose={(date) => {
            
            if (date.length !== 2) date = startDate
            
            setDatepicker(date)
            setDateRange(date)
          }}
          onOpen={(date) => {
            startDate = date
          }}
          options={{
            mode: 'range',
            time_24hr: true,
            weekNumbers: true,
            allowInput: true,
            locale: Czech,
            dateFormat: 'd.m.Y H:i'
            // defaultDate: [new Date(), new Date().setDate(new Date().getDate() + 7)]
          }}
        />
        <ButtonGroup className=''>
          <Button outline className='btn-icon' onClick={moveBackward}>
            <ChevronLeft size={16}/>
          </Button>
          <Button outline className={intervalSelected === 'day' ? 'active' : ''} onClick={setToday}>
            Den
          </Button>
          <Button outline className={intervalSelected === 'week' ? 'active' : ''} onClick={setLastWeek}>
            Týden
          </Button>
          <Button outline className={intervalSelected === 'month' ? 'active' : ''} onClick={setLastMonth}>
            Měsíc
          </Button>
          <Button outline className='btn-icon' onClick={moveForward}>
            <ChevronRight size={16}/>
          </Button>
        </ButtonGroup>
      </div>
    </Fragment>
  )
}

export default PickerRange
