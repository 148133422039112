// ** React Imports
import {Fragment} from 'react'

// ** Custom Components
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'
import PickerRange from "./PickerRange"

// import { useDateRange } from '@hooks/useDateRange'

// ** Styles
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {NavItem, NavLink} from "reactstrap"
import * as Icon from "react-feather"

// eslint-disable-next-line no-unused-vars
const AiDNavbar = props => {
  // ** Props
  // const {setMenuVisibility} = props
  
  return (
    <Fragment>
      {/*<div className='d-flex align-items-center '>*/}
      {/*  <NavbarBookmarks setMenuVisibility={setMenuVisibility} />*/}
      {/*  <PickerRange/>*/}
      {/*</div>*/}
      {/*<NavbarUser skin={skin} setSkin={setSkin} />*/}
    
    </Fragment>
  )
}

export default AiDNavbar
