// ** React Imports
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import {isUserLoggedIn} from '@utils'

// ** Store & Actions
import {useDispatch} from 'react-redux'
import {handleLogout} from '@store/authentication'

// ** Third Party Components
import {User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power} from 'react-feather'

// ** Reactstrap Imports
import {UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem} from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'

const UserDropdownMenu = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  
  // ** State
  const [userData, setUserData] = useState(null)
  
  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])
  
  //** Vars
  // eslint-disable-next-line no-unused-vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar
  
  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item pt-1'>
      <DropdownToggle href='/' tag='a' className='d-flex align-items-center justify-content-end me-1'
                      onClick={e => e.preventDefault()}>
        <div className='d-flex flex-column justify-content-end pe-1'>
          <span
            className='user-name fw-bold text-right text-secondary'>{userData && `${userData.first_name} ${userData.last_name}`}</span>
          <span className='user-status text-end text-secondary'>{(userData && userData.login) || 'username'}</span>
        </div>
        <Avatar initials color={(userData && userData.avatarColor) || 'light-primary'}
                content={`${(userData && userData.first_name) && userData.first_name} ${(userData && userData.last_name) && userData.last_name}`}
                imgHeight='40'
                imgWidth='40' status='online'/>
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to={`/user/view/${userData && userData.id}`}>
          <User size={14} className='me-75'/>
          <span className='align-middle'>Profil</span>
        </DropdownItem>
        <DropdownItem divider/>
        <DropdownItem tag={Link} to='/login' onClick={() => dispatch(handleLogout())}>
          <Power size={14} className='me-75'/>
          <span className='align-middle'>Odhlásit se</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdownMenu
