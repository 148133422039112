// ** React Imports
import {Outlet} from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

import AiDNavbar from "@src/layouts/navbar"
import moment from "moment"
// import {useEffect} from "react"
// import {useDispatch} from "react-redux"
// import 'moment/src/locale/cs'

const VerticalLayout = props => {
  // const [menuData, setMenuData] = useState([])
  
  moment().locale('cs')
  
  return (
    <Layout
      menuData={navigation}
      navbar={props => <AiDNavbar {...props} />}
      {...props}>
      <Outlet/>
    </Layout>
  )
}

export default VerticalLayout
