// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import apiAiD from "@configs/api"
import toast from "react-hot-toast"
import {ToastError, ToastSuccess} from "@utils"


export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get(`${apiAiD.address}/users`)
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get(`${apiAiD.address}/user`, {params})
  return {
    params,
    data: response.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async ({params}) => {
  console.log(params)
  const response = await axios.get(`${apiAiD.address}/users`, {params})
  
  return response.data.users[0]
})

export const addUser = createAsyncThunk('appUsers/addUser', async ({params, callbacks}, {dispatch}) => {
  const response = await axios.post(`${apiAiD.address}/users`, params)
  
  if (response.data.users && response.data.users.length > 0 && Number.isInteger(response.data.users[0].id)) {
    callbacks.onSuccess()
    toast(t => (<ToastSuccess t={t} message="Uspěšné založení uživatele"/>))
    await dispatch(getAllData())
  } else {
    callbacks.onFailed(response.data)
    toast(t => (
      <ToastError t={t}
                  message={`Nezdařilo se uživatele založit. ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
  }
  
  return {data: response.data}
  
})

export const editUser = createAsyncThunk('appUsers/editUser', async ({params, callbacks}, {
  dispatch
}) => {
  
  const response = await axios.put(`${apiAiD.address}/users`, params)
  
  if (response.data.users && response.data.users.length > 0 && Number.isInteger(response.data.users[0].id)) {
    callbacks.onSuccess()
    toast(t => (<ToastSuccess t={t} message="Uspěšné upravení uživatele"/>))
    await dispatch(getAllData())
  } else {
    callbacks.onFailed(response.data)
    toast(t => (
      <ToastError t={t}
                  message={`Nezdařilo se uživatele upravit. ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
  }
  return {
    data: response.data
  }
})

// eslint-disable-next-line no-unused-vars
export const deleteUser = createAsyncThunk('appUsers/deleteUser', async ({params, callbacks}, {dispatch}) => {
  
  const response = await axios.delete(`${apiAiD.address}/users/${params}`)
  
  if (response.data.users && response.data.users.length > 0 && Number.isInteger(response.data.users[0].id)) {
    // callbacks.onSuccess()
    toast(t => (<ToastSuccess t={t} message="Uživatel smazán."/>))
    await dispatch(getAllData())
  } else {
    // callbacks.onFailed(response.data)
    toast(t => (
      <ToastError t={t}
                  message={`Nepodařilo se uživatele smazat. ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
  }
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null,
    groups: []
  },
  reducers: {
    selectUser: (state, action) => {
      state.selected = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload.users
      state.groups = action.payload.groups
    })
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(getUser.fulfilled, (state, action) => {
      
      state.selected = action.payload
    })
  }
})

export default appUsersSlice.reducer

export const {
  selectUser
} = appUsersSlice.actions