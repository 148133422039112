// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiAiD from '@configs/api'
import {ToastSuccess, ToastError, tstzRange2array, getSecondsBetween, getUserPersonal} from '@utils'
import toast from "react-hot-toast"


/* ============================================================================================== */
/*                                            SHIFTS                                              */
/* ============================================================================================== */
export const fetchTable = createAsyncThunk('machine/fetchTable', async ({conf, params}) => {
  
  const response = await axios.get(`${apiAiD.address}${apiAiD.crud}/${conf.table}`, {params})
  return {
    params,
    table: conf.table,
    data: response.data
  }
})

export const addTable = createAsyncThunk('machine/addTable', async ({conf, params, callbacks}, {
  dispatch,
  getState
}) => {
  const response = await axios.post(`${apiAiD.address}${apiAiD.crud}/${conf.table}`, params)
  
  if (response.data[conf.table] && response.data[conf.table].length > 0 && Number.isInteger(response.data[conf.table][0].id)) {
    console.log('success')
    callbacks.onSuccess()
    toast(t => (<ToastSuccess t={t} message={conf.add.success}/>))
    await dispatch(fetchTable({conf, params: getState().machines.params}), getState)
  } else {
    console.log(conf.add.failed)
    callbacks.onFailed(response.data)
    toast(t => (
      <ToastError t={t} message={`${conf.add.failed} ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
  }
  
  return {
    data: response.data
  }
})

export const editTable = createAsyncThunk('machine/editTable', async ({conf, params, callbacks}, {
  dispatch,
  getState
}) => {
  
  const response = await axios.put(`${apiAiD.address}${apiAiD.crud}/${conf.table}`, params)
  
  if (response.data[conf.table] && response.data[conf.table].length > 0 && Number.isInteger(response.data[conf.table][0].id)) {
    callbacks.onSuccess()
    toast(t => (<ToastSuccess t={t} message={conf.edit.success}/>))
    await dispatch(fetchTable({conf, params: getState().machines.params}), getState)
  } else {
    callbacks.onFailed(response.data)
    toast(t => (
      <ToastError t={t} message={`${conf.edit.failed} ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
  }
  return {
    data: response.data
  }
})

// eslint-disable-next-line no-unused-vars
export const deleteTable = createAsyncThunk('machine/deleteTable', async ({conf, params, callbacks}, {
  dispatch,
  getState
}) => {
  const response = await axios.delete(`${apiAiD.address}${apiAiD.crud}/${conf.table}/${params}`)
  
  if (response.data[conf.table] && response.data[conf.table].length > 0 && Number.isInteger(response.data[conf.table][0].id)) {
    // callbacks.onSuccess()
    toast(t => (<ToastSuccess t={t} message={conf.delete.success}/>))
    await dispatch(fetchTable({conf, params: getState().machines.params}), getState)
  } else {
    // callbacks.onFailed(response.data)
    toast(t => (
      <ToastError t={t} message={`${conf.delete.failed} ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
  }
  
  return params.id
})


/* ============================================================================================== */
/*                                   COMPLETE MACHINE DATA GETTER                                 */
/* ============================================================================================== */
export const fetchOverviewData = createAsyncThunk('machine/fetchOverviewData', async ({params}) => {
  const response = await axios.get(`${apiAiD.address}/overview-data`, {params})
  return {
    params,
    data: response.data
  }
})

export const fetchCompleteDowntimeData = createAsyncThunk('machine/fetchCompleteDowntimeData', async ({params}) => {
  const response = await axios.get(`${apiAiD.address}/complete-downtime`, {params})
  return {
    params,
    data: response.data
  }
})

export const fetchMyDowntimeEvents = createAsyncThunk('machine/fetchMyDowntimeEvents', async () => {
  const response = await axios.get(`${apiAiD.address}/my-downtime-events`)
  return {
    data: response.data
  }
})

export const fetchDowntimeAssignmentsTypes = createAsyncThunk('machine/fetchDowntimeAssignmentsTypes', async () => {
  const response = await axios.get(`${apiAiD.address}/downtime-assignments-types`)
  return {
    data: response.data
  }
})

export const putOvertakeDowntimeEvents = createAsyncThunk('machine/putOvertakeDowntimeEvents',
  async ({
           params,
           callbacks
         }, {
           dispatch,
           getState
         }) => {
    
    const response = await axios.put(`${apiAiD.address}/overtake-downtime-events`, params)
    
    if (response.data['overtakeDowntime'] && response.data['overtakeDowntime'].length > 0) {
      callbacks.onSuccess()
      toast(t => (<ToastSuccess t={t} message={"Úspěšné převzetí prostoje."}/>))
      await dispatch(fetchMyDowntimeEvents(), getState)
    } else {
      callbacks.onFailed(response.data)
      toast(t => (
        <ToastError t={t}
                    message={`Prostoj se nepodařilo převzít ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
    }
    
    return {
      data: response.data
    }
  })

export const putDeleteShifts = createAsyncThunk('machine/putDeleteShifts',
  async ({
           params,
           callbacks
         }, {
           dispatch,
           getState
         }) => {
    
    const response = await axios.put(`${apiAiD.address}/delete-shifts`, params)
    
    if (response.data['shifts'] && response.data['shifts'].length > 0) {
      callbacks.onSuccess()
      toast(t => (<ToastSuccess t={t}
                                message={`Úspěšné smazání směn.\nPočet smazaných směn: ${response.data['shifts'].length}`}/>))
      await dispatch(fetchTable({conf: {table: 'shifts'}, params: getState().machines.params}), getState)
    } else {
      callbacks.onFailed(response.data)
      toast(t => (
        <ToastError t={t}
                    message={`Směny nebylo možné smazat ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
    }
    
    return {
      data: response.data
    }
  })

export const postGenerateShifts = createAsyncThunk('machine/putGenerateShifts',
  async ({
           params,
           callbacks
         }, {
           dispatch,
           getState
         }) => {
    
    const response = await axios.post(`${apiAiD.address}/generate-shifts`, params)
    
    if (response.data['newShifts'] && response.data['newShifts'].length > 0) {
      callbacks.onSuccess()
      toast(t => (<ToastSuccess t={t}
                                message={`Založení směn.\nPočet nově založených směn: ${response.data['newShifts'].length}`}/>))
      await dispatch(fetchTable({conf: {table: 'shifts'}, params: getState().machines.params}), getState)
    } else {
      callbacks.onFailed(response.data)
      toast(t => (
        <ToastError t={t}
                    message={`Směny nebylo možné vytvořit ${response.data.error ? `\n ${response.data.error}` : ``}`}/>))
    }
    
    return {
      data: response.data
    }
  })

/* ============================================================================================== */
/*                                                                                                */
/* ============================================================================================== */

const machineDataModel = {
  shifts: {
    actual: [],
    selected: null
  },
  operation_events: {
    actual: [],
    selected: null,
    times: {
      real: null,
      erp: null
    }
  },
  shifts_templates: {
    actual: [],
    selected: null
  },
  machine_events: {
    actual: [],
    selected: null
  },
  logon_events: {
    actual: [],
    selected: null,
    active: null
  },
  downtime_events: {
    actual: [],
    selected: null,
    active: null
  },
  downtime_assignments: {
    actual: [],
    selected: null
  },
  machine_efficiency: {
    sum_shifts: null,
    sum_downtime_events: null,
    sum_logon_events: null,
    sum_downtime_logon_interception: null
  },
  lastRawRecord: null
}

const arrayTypes = ['shifts', 'machine_events', 'logon_events', 'downtime_events', 'operation_events']

export const appMachinesSlice = createSlice({
  name: 'machine',
  initialState: {
    machine: new Array(7).fill(machineDataModel),
    downtime_types: {
      actual: []
    },
    params: null,
    myDowntimeEvents: {
      actual: [],
      selected: null
    }
  },
  reducers: {
    selectShift: (state, action) => {
      state.machine[action.payload.machineId].shifts.selected = action.payload.data
    },
    selectDowntimeAssignment: (state, action) => {
      state.machine[action.payload.machineId].downtime_assignments.selected = action.payload.data
    },
    selectDowntimeEvent: (state, action) => {
      state.machine[action.payload.machineId].downtime_events.selected = action.payload.data
    },
    selectMyDowntimeEvent: (state, action) => {
      state.myDowntimeEvents.selected = action.payload.data
    },
    selectMachineEvent: (state, action) => {
      state.machine[action.payload.machineId].machine_events.selected = action.payload.data
    },
    selectLogonEvent: (state, action) => {
      state.machine[action.payload.machineId].logon_events.selected = action.payload.data
    },
    selectOperationEvent: (state, action) => {
      state.machine[action.payload.machineId].operation_events.selected = action.payload.data
    }
  },
  extraReducers: builder => {
    //TODO: validace prijatych dat - https://github.com/jquense/yup
    builder.addCase(fetchTable.fulfilled, (state, action) => {
      state.params = action.payload.params
      
      if (action.payload.params === null) {
        state[action.payload.table].actual = action.payload.data[action.payload.table]
        return
      }
      
      state.machine[action.payload.params.machine_id].operation_events.times.real = null
      state.machine[action.payload.params.machine_id].operation_events.times.erp = null
      
      if (!Array.isArray(state.machine[action.payload.params.machine_id][action.payload.table].actual) || !Array.isArray(action.payload.data[action.payload.table])) return
      
      let sumDurationS = 0
      let sumERPTimeS = 0
      state.machine[action.payload.params.machine_id][action.payload.table].actual = action.payload.data[action.payload.table].map(el => {
        let date = {}
        let default_delay = {}
        let expected_delay = {}
        let operation_erp = {}
        let originator = {}
        
        if ('duration' in el) {
          const dateArray = tstzRange2array(el.duration)
          date = {start_date: dateArray[0], end_date: dateArray[1]}
        }
        if ('default_delay' in el) {
          default_delay = {default_delay_minutes: el.default_delay / 60}
        }
        if ('expected_delay' in el) {
          expected_delay = {expected_delay_minutes: el.expected_delay / 60}
        }
        
        if ('originators' in el) {
          if (Array.isArray(el.originators) && el.originators.length > 0) originator = {originator: getUserPersonal(el.originators[0])}
        }
        
        if ('order_nr' in el && 'operation_nr' in el && Array.isArray(action.payload.data.erp_operations)) {
          const assignOperation = action.payload.data.erp_operations.find(erp => erp.cislo_vyr_prikaz === el.order_nr && erp.cislo_operace === el.operation_nr)
          
          operation_erp = {
            erp_operation_name: assignOperation ? assignOperation.nazev_operace : '',
            erp_operation_time_s: assignOperation ? assignOperation.cas_operace_s * 60 : 0
          }
          
          sumERPTimeS += assignOperation ? assignOperation.cas_operace_s * 60 : 0
          sumDurationS += getSecondsBetween(date)
          
        }
        
        if (action.payload.table === 'operation_events') {
          state.machine[action.payload.params.machine_id].operation_events.times.real = sumDurationS > 0 ? sumDurationS : null
          state.machine[action.payload.params.machine_id].operation_events.times.erp = sumERPTimeS > 0 ? sumERPTimeS : null
        }
        
        
        return {...el, ...date, ...default_delay, ...expected_delay, ...operation_erp, ...originator}
      })
      
    })
    builder.addCase(fetchOverviewData.fulfilled, (state, action) => {
      state.params = action.payload.params
      
      state.machine[action.payload.params.machine_id].lastRawRecord = action.payload.data.lastRawRecord ? action.payload.data.lastRawRecord : Date()
      
      if (action.payload.data.activeLogon && action.payload.data.activeLogon.length > 0) {
        const dateArray = tstzRange2array(action.payload.data.activeLogon[0].duration)
        state.machine[action.payload.params.machine_id].logon_events.active = {
          ...action.payload.data.activeLogon[0],
          start_date: dateArray[0],
          end_date: dateArray[1]
        }
      } else {
        state.machine[action.payload.params.machine_id].logon_events.active = null
      }
      
      if (action.payload.data.activeDowntime && action.payload.data.activeDowntime.length > 0) {
        const dateArray = tstzRange2array(action.payload.data.activeDowntime[0].duration)
        state.machine[action.payload.params.machine_id].downtime_events.active = {
          ...action.payload.data.activeDowntime[0],
          start_date: dateArray[0],
          end_date: dateArray[1],
          expected_delay: action.payload.data.activeDowntime[0].expected_delay / 60
        }
      } else {
        state.machine[action.payload.params.machine_id].downtime_events.active = null
      }
      
      if (action.payload.data.machine_efficiency && action.payload.data.machine_efficiency.length > 0) {
        
        state.machine[action.payload.params.machine_id].machine_efficiency.sum_shifts = action.payload.data.machine_efficiency[0]
        state.machine[action.payload.params.machine_id].machine_efficiency.sum_downtime_events = action.payload.data.machine_efficiency[1]
        state.machine[action.payload.params.machine_id].machine_efficiency.sum_logon_events = action.payload.data.machine_efficiency[2]
        state.machine[action.payload.params.machine_id].machine_efficiency.sum_downtime_logon_interception = action.payload.data.machine_efficiency[3]
      } else {
        Object.keys(state.machine[action.payload.params.machine_id].machine_efficiency).forEach(function (index) {
          state.machine[action.payload.params.machine_id].machine_efficiency[index] = null
        })
      }
      
      arrayTypes.forEach(d => {
        if (Array.isArray(action.payload.data[d])) {
          state.machine[action.payload.params.machine_id][d].actual = action.payload.data[d].map(el => {
            const dateArray = tstzRange2array(el.duration)
            if (d === 'downtime_events') {
              return {
                ...el,
                start_date: dateArray[0],
                end_date: dateArray[1],
                expected_delay: el.expected_delay / 60
              }
            } else {
              return {...el, start_date: dateArray[0], end_date: dateArray[1]}
            }
          })
        } else {
          state.machine[action.payload.params.machine_id][d].actual = []
        }
      })
      
    })
    builder.addCase(fetchCompleteDowntimeData.fulfilled, (state, action) => {
      state.params = action.payload.params
      
      state.downtime_types.actual = action.payload.data.downtime_types
      
      state.machine[action.payload.params.machine_id].downtime_events.actual = action.payload.data.downtime_events.map(el => {
        const dateArray = tstzRange2array(el.duration)
        let originator = {}
        if ('originators' in el) {
          if (Array.isArray(el.originators) && el.originators.length > 0) originator = {originator: getUserPersonal(el.originators[0])}
        }
        
        return ({
          ...el,
          start_date: dateArray[0],
          end_date: dateArray[1],
          expected_delay_minutes: el.expected_delay / 60,
          ...originator
        })
      })
      state.machine[action.payload.params.machine_id].downtime_assignments.actual = action.payload.data.downtime_assignments.map(el => ({
        ...el,
        default_delay_minutes: el.default_delay / 60
      }))
      
    })
    builder.addCase(fetchDowntimeAssignmentsTypes.fulfilled, (state, action) => {
      state.params = action.payload.params
      
      state.downtime_types.actual = action.payload.data.downtime_types
      
      
      for (let i = 0; i < state.machine.length; i++) {
        state.machine[i].downtime_assignments.actual = action.payload.data.downtime_assignments.filter(el => el.machine_id === i).map(el => ({
          ...el,
          default_delay_minutes: el.default_delay / 60
        }))
      }
      
    })
    builder.addCase(fetchMyDowntimeEvents.fulfilled, (state, action) => {
      
      state.myDowntimeEvents.actual = action.payload.data.myDowntimeEvents.map(el => {
        const dateArray = tstzRange2array(el.duration)
        return ({
          ...el,
          start_date: dateArray[0],
          end_date: dateArray[1],
          expected_delay_minutes: el.expected_delay / 60
        })
      })
      
    })
  }
})

export const {
  selectShift,
  selectDowntimeAssignment,
  selectDowntimeEvent,
  selectMachineEvent,
  selectLogonEvent,
  selectMyDowntimeEvent,
  selectOperationEvent
} = appMachinesSlice.actions

export default appMachinesSlice.reducer
