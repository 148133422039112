// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'
import moment from "moment"

const from = moment()

from.hour(0)
from.minute(0)
from.second(0)

const to = from.clone()
to.add(1, 'day')
export const timerangeSlice = createSlice({
  name: 'timerange',
  initialState: {
    dateRange: [from.toDate(), to.toDate()]
  },
  reducers: {
    handleDateRange: (state, action) => {
      state.dateRange = action.payload
    }
  }
})

export const {
  handleDateRange
} = timerangeSlice.actions

export default timerangeSlice.reducer
