// ** React Imports
// import { useEffect } from 'react'

// ** Store Imports
import { handleDateRange } from '@store/timerange'
import { useDispatch, useSelector } from 'react-redux'

export const useDateRange = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const store = useSelector(state => state.timerange)

  const setDateRange = dateRange => {
    dispatch(handleDateRange(dateRange))
  }

  // useEffect(() => {
  //   // console.log(store.dateRange)
  // }, [store.dateRange])

  return { dateRange: store.dateRange, setDateRange }
}
