import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import apiAiD from "@configs/api"
import {tstzRange2array} from "@utils"
import moment from "moment"

export const fetchCompleteOverviewData = createAsyncThunk('overviewData/fetchCompleteOverviewData', async () => {
  const response = await axios.get(`${apiAiD.address}/complete-overview`)
  return {
    data: response.data
  }
})

const machineOverviewDataModel = {
  shifts: {
    duration: null,
    start_date: null,
    end_date: null,
    logon_sum: null
  },
  machine_events: [ // act / on
    {
      duration: null,
      start_date: null,
      end_date: null
    },
    {
      duration: null,
      start_date: null,
      end_date: null
    }
  ],
  logon_events: {
    user: null,
    personal_nr: null,
    duration: null,
    start_date: null,
    end_date: null
  },
  downtime_events: {
    name: null,
    personal_nr: null,
    duration: null,
    start_date: null,
    end_date: null,
    responsible_user: null,
    expected_delay_minutes: null
  },
  lastRawRecord: null
}

export const appOverviewDataSlice = createSlice({
  name: 'overviewData',
  initialState: {
    machine: new Array(7).fill(machineOverviewDataModel),
    kioskMode: false
  },
  reducers: {
    selectKioskMode: (state, action) => {
      state.kioskMode = action.payload.data
    }
  },
  extraReducers: builder => {
    //TODO: validace prijatych dat - https://github.com/jquense/yup
    builder.addCase(fetchCompleteOverviewData.fulfilled, (state, action) => {
      
      if (Array.isArray(action.payload.data)) for (const machine of action.payload.data) {
        
        //SHIFTS
        state.machine[machine.machine_id].shifts.duration = machine.duration_shift
        let tempRange = tstzRange2array(machine.duration_shift)
        state.machine[machine.machine_id].shifts.start_date = tempRange[0]
        state.machine[machine.machine_id].shifts.end_date = tempRange[1]
        state.machine[machine.machine_id].shifts.logon_sum = machine.logon_sum
        
        //DOWNTIME
        state.machine[machine.machine_id].downtime_events.name = machine.name
        state.machine[machine.machine_id].downtime_events.personal_nr = machine.downtime_personal_nr
        state.machine[machine.machine_id].downtime_events.responsible_user = machine.downtime_user
        state.machine[machine.machine_id].downtime_events.expected_delay_minutes = machine.expected_delay / 60
        state.machine[machine.machine_id].downtime_events.duration = machine.duration_downtime
        tempRange = tstzRange2array(machine.duration_downtime)
        state.machine[machine.machine_id].downtime_events.start_date = tempRange[0]
        state.machine[machine.machine_id].downtime_events.end_date = tempRange[1]
        
        //MACHINE EVENTS
        state.machine[machine.machine_id].machine_events[0].duration = machine.duration_on
        tempRange = tstzRange2array(machine.duration_on)
        state.machine[machine.machine_id].machine_events[0].start_date = tempRange[0]
        state.machine[machine.machine_id].machine_events[0].end_date = tempRange[1]
        
        state.machine[machine.machine_id].machine_events[1].duration = machine.duration_act
        tempRange = tstzRange2array(machine.duration_act)
        state.machine[machine.machine_id].machine_events[1].start_date = tempRange[0]
        state.machine[machine.machine_id].machine_events[1].end_date = tempRange[1]
        
        //LOGON EVENTS
        state.machine[machine.machine_id].logon_events.user = machine.logon_user
        state.machine[machine.machine_id].logon_events.personal_nr = machine.logon_user_personal_nr
        state.machine[machine.machine_id].logon_events.duration = machine.duration_logon
        tempRange = tstzRange2array(machine.duration_logon)
        state.machine[machine.machine_id].logon_events.start_date = tempRange[0]
        state.machine[machine.machine_id].logon_events.end_date = tempRange[1]
        
        //LAST RECORD
        state.machine[machine.machine_id].lastRawRecord = moment(machine.tstamp)
        
      }
      
    })
  }
})

export const {
  selectKioskMode
} = appOverviewDataSlice.actions


export default appOverviewDataSlice.reducer