// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const jwtConfig = {
  loginEndpoint: 'https://auth-axis.epr-liberec.cz/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: 'https://auth-axis.epr-liberec.cz/token',
  logoutEndpoint: 'https://auth-axis.epr-liberec.cz/logout',
  
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  
  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}


const {jwt} = useJwt(jwtConfig)

export default jwt
